import React, { Component } from 'react'; //different
import './App.css';
import Carousel from './carousel';
// import { isMobile } from 'react-device-detect';

let isMobile = false;
if (window.innerWidth <= 760) isMobile = true;
class App extends Component {


    onClick = (e) => {
        e.preventDefault()
        ////console.log('onclick..')
    }
    render() {
        const isMobile = true;
        return (
            < >
               
                <div   style={{ display: isMobile ? "none" : "" }} className="MuiBox-root css-10pci0o">
                    <div className="MuiBox-root css-1gb624v">
                        <div className="MuiBox-root css-0">
                            <span
                                style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%"
                                }}
                            >
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "block",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        maxWidth: "100%"
                                    }}
                                >
                                    <img
                                        alt=""
                                        aria-hidden="true"
                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27186%27%20height=%2751%27/%3e"
                                        style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0
                                        }}
                                    />
                                </span>
                                <img
                                    alt="logo"
                                    srcSet="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Ffooter%2Fbanner_1.png&w=256&q=75 1x, https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Ffooter%2Fbanner_1.png&w=384&q=75 2x"
                                    src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Ffooter%2Fbanner_1.png&w=384&q=75"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                        position: "absolute",
                                        inset: 0,
                                        boxSizing: "border-box",
                                        padding: 0,
                                        border: "none",
                                        margin: "auto",
                                        display: "block",
                                        width: 0,
                                        height: 0,
                                        minWidth: "100%",
                                        maxWidth: "100%",
                                        minHeight: "100%",
                                        maxHeight: "100%"
                                    }}
                                />
                            </span>
                        </div>
                        <div className="MuiBox-root css-0" style={{ marginLeft: 20 }}>
                            <span
                                style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%"
                                }}
                            >
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "block",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        maxWidth: "100%"
                                    }}
                                >
                                    <img
                                        alt=""
                                        aria-hidden="true"
                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2791%27%20height=%2742%27/%3e"
                                        style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0
                                        }}
                                    />
                                </span>
                                <img
                                    alt="logo"
                                    srcSet="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Ffooter%2Fbanner_2.png&w=96&q=75 1x, https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Ffooter%2Fbanner_2.png&w=256&q=75 2x"
                                    src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Ffooter%2Fbanner_2.png&w=256&q=75"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                        position: "absolute",
                                        inset: 0,
                                        boxSizing: "border-box",
                                        padding: 0,
                                        border: "none",
                                        margin: "auto",
                                        display: "block",
                                        width: 0,
                                        height: 0,
                                        minWidth: "100%",
                                        maxWidth: "100%",
                                        minHeight: "100%",
                                        maxHeight: "100%"
                                    }}
                                />
                            </span>
                        </div>
                        <div className="MuiBox-root css-0" style={{ marginLeft: 20 }}>
                            <span
                                style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%"
                                }}
                            >
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "block",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        maxWidth: "100%"
                                    }}
                                >
                                    <img
                                        alt=""
                                        aria-hidden="true"
                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2766%27%20height=%2751%27/%3e"
                                        style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0
                                        }}
                                    />
                                </span>
                                <img
                                    alt="logo"
                                    srcSet="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Ffooter%2Fbanner_3.png&w=96&q=75 1x, https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Ffooter%2Fbanner_3.png&w=256&q=75 2x"
                                    src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Ffooter%2Fbanner_3.png&w=256&q=75"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    style={{
                                        position: "absolute",
                                        inset: 0,
                                        boxSizing: "border-box",
                                        padding: 0,
                                        border: "none",
                                        margin: "auto",
                                        display: "block",
                                        width: 0,
                                        height: 0,
                                        minWidth: "100%",
                                        maxWidth: "100%",
                                        minHeight: "100%",
                                        maxHeight: "100%"
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="MuiBox-root css-he5ki4">
                        <div className="MuiBox-root css-1caud2s">
                            <div className="MuiBox-root css-sohav1">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27140%27%20height=%2750%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="logo"
                                       
                                        src="/build/web/igame-index-lobby-wm/img/logo.png"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%",
                                            objectFit: "contain"
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="MuiBox-root css-1wrv3o0">
                                <p className="MuiTypography-root MuiTypography-body1 css-pwaqkg">
                                    Version 1.0.0 Copyright © 2022 Supported by ump.bet สล็อตออนไลน์
                                    ระบบออโต้ทันสมัย ฝาก – ถอน ภายใน 3 วิ
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

          
            </>

        );
    }
}
export default App;
