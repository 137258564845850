import React, { Component } from 'react';
import { Image, Row, Col, Container } from 'react-bootstrap';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GamepadIcon from '@mui/icons-material/Gamepad';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { StarBorder, AccessTime, FormatListNumberedRtlIcon, Group, Person } from '@mui/icons-material';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import moment from 'moment';
import instance from "../axios-instance";
import Systems from "../SystemFunction";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}


class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainTaps: 0,
            roomTaps: 0,
            budget: null,
            selected: null,
            point: 0,
            playerid: null,
            isOpenCreate: false,
            roomLists: [],
            roomplayerLists: [],
            roomhistoryLists: [],
            roomrankingLists: [],
        }
    }
    clearChecked = () => {
        const { selectedChecked, budgetChecked } = this.state

        for (let index = 0; index < selectedChecked.length; index++) {
            const element = selectedChecked[index];
            element.checked = false
        }
        for (let index = 0; index < budgetChecked.length; index++) {
            const element = budgetChecked[index];
            element.checked = false
        }
    }
    async componentDidMount() {
        var token = localStorage.getItem('auth_token');
        var selected = document.getElementsByName("selected")
        var budget = document.getElementsByName("budget")
        if (token != null) {
            var decoded = jwt_decode(token);
            console.log(decoded)
            this.setState({
                playerid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                loginstate: true,
                selectedChecked: selected,
                budgetChecked: budget
            });
            this.bindList(decoded.message.playerid)

        }
        else {
            Swal.fire({
                title: 'Error!',
                text: 'คุณไม่ได้ Login !!',
                icon: 'error',
                confirmButtonText: 'ปิด'
            })
            this.setState({
                loginstate: false,
            });            
            window.location.href = '/';
        }

    }

    handlechange = (e) => {
        const { selected, budget } = this.state
        this.setState({
            [e.target.name]: e.target.value
        })
        if ((e.target.name === "budget" && selected !== null)) {
            var data = {
                budget: e.target.value,
                selected: selected
            }
            this.handleCreateRoom(data)
        }
        else if ((e.target.name === "selected" && budget !== null)) {
            var datas = {
                budget: budget,
                selected: e.target.value
            }
            this.handleCreateRoom(datas)
        }
    }
    handleCreateRoom = (data) => {
        const { user_id, playerid } = this.state
        var token = localStorage.getItem('auth_token');
        if (data.selected === null) {
            return Swal.fire({
                title: 'Warning!',
                text: 'โปรดเลือก ตัวเลือก !!',
                icon: 'warning',
                confirmButtonText: 'ปิด'
            })
        }
        if (data.budget === null) {
            return Swal.fire({
                title: 'Warning!',
                text: 'โปรดเลือก เงินเดิมพัน !!',
                icon: 'warning',
                confirmButtonText: 'ปิด'
            })
        }
        if (token != null) {

            try {
                console.log(token);
                instance.post("/api/v1/checkpoint", {
                    userid: playerid,
                    System: Systems,
                    Function: 'rechecklogin-App.js'
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    console.log(res.data.message)
                    var point = res.data.message.amount
                    if (point >= data.budget) {
                        Swal.fire({
                            title: 'ยืนยันการสร้างห้อง',
                            text: `จำนวนเดิมพันของคุณคือ ${data.budget} คะแนน ตัวเลือกคือ 
                            ${Number(data.selected) === 1 ? "กระดาษ" : Number(data.selected) === 2 ? "ค้อน" : Number(data.selected) === 3 ? "กรรไกร" : "แบบสุ่ม"}`,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'ยืนยัน',
                            cancelButtonText: 'ยกเลิก',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                var _selected = Number(data.selected)
                                if (Number(data.selected) === 4) {
                                    var int = Math.floor(Math.random() * 3) + 1;

                                    _selected = int
                                }
                                instance.post("/api/v1/game/initial", {
                                    gameid: 1,
                                    System: Systems,
                                    playerid: playerid,
                                    selected: Number(_selected),
                                    point: Number(data.budget)
                                }, {
                                    headers: { Authorization: `Bearer ${token}` }
                                }).then((data) => {
                                    this.setState({
                                        budget: null,
                                        selected: null,
                                        point: 0,
                                    })
                                    this.clearChecked();
                                    Swal.fire({
                                        title: 'Success',
                                        text: 'สร้างห้องเรียบร้อยแล้ว',
                                        icon: 'success',
                                        confirmButtonText: 'ปิด'
                                    })
                                    this.bindList(playerid)
                                }).catch((err) => {
                                    this.setState({
                                        budget: null,
                                        selected: null,
                                        point: 0,
                                    })
                                    this.clearChecked();
                                    Swal.fire({
                                        title: 'Error!',
                                        text: JSON.stringify(err),
                                        icon: 'error',
                                        confirmButtonText: 'ปิด'
                                    })
                                })
                            } else {
                                this.setState({
                                    budget: null,
                                    selected: null,
                                    point: 0,
                                })
                                this.clearChecked();
                            }
                        })

                    } else {
                        this.setState({
                            budget: null,
                            selected: null,
                            point: 0,
                        })
                        this.clearChecked();
                        Swal.fire({
                            title: 'Error!',
                            text: 'แต้มไม่พอจะสร้างห้อง',
                            icon: 'error',
                            confirmButtonText: 'ปิด'
                        })
                    }
                }).catch((err) => {
                    if (err.response.status === 401 || err.response.status === 403) {
                        localStorage.clear();
                        // this.props.history.push(
                        //     `/`
                        // );
                    }
                });

            }
            catch (ex) {
                this.clearChecked();
                this.setState({
                    budget: null,
                    selected: null,
                    point: 0,
                })
            }
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: 'คุณไม่ได้ Login !!',
                icon: 'error',
                confirmButtonText: 'ปิด'
            })
            window.location.href = '/';
        }
    }
    bindList = (playerid) => {
        console.log(playerid)
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            try {
                instance.get("/api/v1/game/getplayroom", {
                    params: {
                        playerid: playerid,
                        page: 1
                    },
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    console.log(res);
                    var newState = []
                    Object.keys(res.data.message.gamelist).forEach((item) => {
                        // var date = moment.tz(item.datecreate, "Asia/Bangkok").format('YYYY-MM-DD HH:mm:ss')
                        newState.push({
                            no: item.no,
                            pointamount: item.pointamount,
                            orderid: item.orderid,
                            datecreate: item.datecreate
                        })
                    })
                    this.setState({
                        roomLists: newState
                    })
                }).catch((err) => {
                    if (err.response.status === 401 || err.response.status === 403) {
                        localStorage.clear();
                        this.props.history.push(
                            `/`
                        );
                    }
                });
            }
            catch (ex) {
                console.log(ex);
            }
        }
        else{
            Swal.fire({
                title: 'Error!',
                text: 'คุณไม่ได้ Login !!',
                icon: 'error',
                confirmButtonText: 'ปิด'
            })
            window.location.href = '/';
        }
    }
    bindListplayer = (playerid) => {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            try {
                instance.get("/api/v1/game/getplayroomplayer", {
                    params: {
                        playerid: playerid,
                        page: 1
                    },
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    var newState = []
                    Object.keys(res.data.message.gamelist).forEach((item) => {
                        // var date = moment.tz(item.datecreate, "Asia/Bangkok").format('YYYY-MM-DD HH:mm:ss')
                        newState.push({
                            no: item.no,
                            pointamount: item.pointamount,
                            orderid: item.orderid,
                            datecreate: item.datecreate,
                            selected: item.selected
                        })
                    })
                    this.setState({
                        roomplayerLists: newState
                    })
                    console.log(newState);
                }).catch((err) => {
                    if (err.response.status === 401 || err.response.status === 403) {
                        localStorage.clear();
                        this.props.history.push(
                            `/`
                        );
                    }
                });
            }
            catch (ex) {
                console.log(ex);
            }
        }
        else{
            Swal.fire({
                title: 'Error!',
                text: 'คุณไม่ได้ Login !!',
                icon: 'error',
                confirmButtonText: 'ปิด'
            })
            window.location.href = '/';
        }
    }
    bindListhistory = (playerid) => {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            try {
                instance.get("/api/v1/game/getplayplayerhistory", {
                    params: {
                        playerid: playerid,
                        page: 1
                    },
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    var newState = []
                    Object.keys(res.data.message.gamelist).forEach((item) => {
                        // var date = moment.tz(item.datecreate, "Asia/Bangkok").format('YYYY-MM-DD HH:mm:ss')
                        newState.push({
                            no: item.no,
                            pointamount: item.pointamount,
                            orderid: item.orderid,
                            datecreate: item.datecreate,
                            selected: item.selected,
                            enemyselected: item.enemyselected,
                            wintype: item.wintype,
                            userwin: item.userwin,
                            usercreate: item.usercreate,
                            userapporve: item.userapporve,
                        })
                    })
                    this.setState({
                        roomhistoryLists: newState
                    })

                }).catch((err) => {
                    if (err.response.status === 401 || err.response.status === 403) {
                        localStorage.clear();
                        this.props.history.push(
                            `/`
                        );
                    }
                });
            }
            catch (ex) {
                console.log(ex);
            }
        }
        else{
            Swal.fire({
                title: 'Error!',
                text: 'คุณไม่ได้ Login !!',
                icon: 'error',
                confirmButtonText: 'ปิด'
            })
            window.location.href = '/';
        }
    }

    bindListranking = (playerid) => {

        var token = localStorage.getItem('auth_token');
        if (token != null) {
            try {
                instance.get("/api/v1/game/getplayerrating", {
                    params: {
                        playerid: playerid,
                        page: 1
                    }, headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    var newState = []
                    Object.keys(res.data.message.gamelist).forEach((item) => {
                        // var date = moment.tz(item.datecreate, "Asia/Bangkok").format('YYYY-MM-DD HH:mm:ss')
                        newState.push({
                            point: item.test,
                            playerid: item.playerid,
                            user_id: item.userwin,
                            totalplays: item.totalplay,
                        })
                    })
                    this.setState({
                        roomrankingLists: newState
                    })

                }).catch((err) => {
                    if (err.response.status === 401 || err.response.status === 403) {
                        localStorage.clear();
                        this.props.history.push(
                            `/`
                        );
                    }
                });
            }
            catch (ex) {
                console.log(ex);
            }
        }else
        {
            Swal.fire({
                title: 'Error!',
                text: 'คุณไม่ได้ Login !!',
                icon: 'error',
                confirmButtonText: 'ปิด'
            })
            window.location.href = '/';
        }
    }
    handleChangeMainTap = (event, newValue) => {
        this.setState({ mainTaps: newValue })
        const { user_id, playerid } = this.state
        console.log(newValue);
        if (newValue === 1) {
            this.bindListhistory(playerid)
        }
        else if (newValue === 2) {

            this.bindListranking(playerid)
        }
    };
    handleChangeTap = (event, newValue) => {
        this.setState({ roomTaps: newValue })
        const { user_id, playerid } = this.state
        if (newValue === 1) {
            this.bindListplayer(playerid)
        }
    };

    handleGame = (e, data) => {
        const { user_id, playerid } = this.state
        var token = localStorage.getItem('auth_token');
        var selected = e.target.value
        e.target.checked = false
        if (token != null) {
            try {
                instance.post("/api/v1/checkpoint", {
                    userid: playerid,
                    System: Systems,
                    Function: 'rechecklogin-App.js'
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    var point = res.data.message.Point
                    if (point >= data.pointamount) {
                        Swal.fire({
                            title: 'ยืนยันการเล่น',
                            text: `ห้องนี้แต้มดิมพันคือ ${data.pointamount} คะแนน ตัวเลือกคือ 
                            ${Number(selected) === 1 ? "กระดาษ" : Number(selected) === 2 ? "ค้อน" : Number(selected) === 3 ? "กรรไกร" : "แบบสุ่ม"}`,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'ยืนยัน',
                            cancelButtonText: 'ยกเลิก',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                var _selected = Number(selected)
                                instance.post("/api/v1/game/gameplay", {
                                    playerid: playerid,
                                    System: Systems,
                                    roomid: data.orderid,
                                    selected: Number(_selected)
                                }, { headers: { Authorization: `Bearer ${token}` } }).then(async (res) => {
                                    var isWin = res.data.win
                                    var point = res.data.pointrecived
                                    await Swal.fire({
                                        title: `${Number(isWin) === 1 ? `คุณชนะเกมส์นี้ คะแนนที่ได้รับคือ ${point} คะแนน !!` : Number(isWin) === 2 ?
                                            `คุณเสมอเกมส์นี้ คะแนนที่ได้รับคือ ${point} คะแนน !!` : `คุณแพ้เกมส์นี้ !!`}`,
                                        icon: `${Number(isWin) === 1 ? `success` : Number(isWin) === 2 ?
                                            `error` : `error`}`,
                                        confirmButtonText: 'ปิด'
                                    })

                                    this.bindList(playerid)
                                }).catch((err) => {

                                    Swal.fire({
                                        title: 'Error!',
                                        text: JSON.stringify(err),
                                        icon: 'error',
                                        confirmButtonText: 'ปิด'
                                    })
                                })
                            }
                        })

                    } else {
                        this.bindList(playerid)
                        Swal.fire({
                            title: 'Error!',
                            text: 'แต้มไม่พอ !!',
                            icon: 'error',
                            confirmButtonText: 'ปิด'
                        })
                    }
                })
                    .catch((err) => {
                        if (err.response.status === 401 || err.response.status === 403) {
                            localStorage.clear();
                            this.props.history.push(
                                `/`
                            );
                        }
                    });;

            }
            catch (ex) {
                this.bindList(playerid)
            }
        }
        else{
            Swal.fire({
                title: 'Error!',
                text: 'คุณไม่ได้ Login !!',
                icon: 'error',
                confirmButtonText: 'ปิด'
            })
            window.location.href = '/';
        }
    };
    handleTabs = (event, newValue) => {
        this.setState({ taps: newValue })
    }
    historyimage(value, userid) {
        const { member_id, playerid } = this.state
        if (value === 1) {
            return <label className="for-checkbox-tools" htmlhtmlFor={"paper" + value.no}>
                <Image src="img/hand-paper.png" className="game-image" width="80px" /><br /> {(userid === playerid) ? "You" : "Other"}
            </label>

        } else if (value === 2) {
            return <label className="for-checkbox-tools" htmlhtmlFor={"rock" + value.no}>
                <Image src="img/hand-rock.png" className="game-image" width="80px" /><br /> {(userid === playerid) ? "You" : "Other"}
            </label>

        } else if (value === 3) {
            return <label className="for-checkbox-tools" htmlhtmlFor={"scissors" + value.no}>
                <Image src="img/hand-scissors.png" className="game-image" width="80px" /><br /> {(userid === playerid) ? "You" : "Other"}
            </label>

        }

    }

    checkwinlose(userwin, value, price) {

        const { playerid } = this.state
        if (value === 0 && playerid === userwin) {
            return <Chip className="for-point-win"
                label={"ชนะ " + price + "P"
                }
            />
        }
        else if (value === 0 && playerid !== userwin) {
            return <Chip className="for-point-lose"
                label={"แพ้ " + price + "P"} />
        }
        else if (value === 1 && playerid === userwin) {
            return <Chip className="for-point-win"
                label={"ชนะ " + price + "P"}
            />
        }
        else if (value === 1 && playerid !== userwin) {
            return <Chip className="for-point-lose"
                label={"แพ้ " + price + "P"} />
        }
        else if (value === 2) {
            return <Chip className="for-point-draw"
                label={"เสมอ " + price + "P"}
            />
        }

    }

    render() {
        const { roomLists, roomplayerLists, roomhistoryLists, roomrankingLists } = this.state
        return (
            <section className="x-index-top-container">
                <div className="-index-inner-wrapper" style={{ backgroundColor: "black" }}>
                    <div className="row" id="item-zone" style={{ backgroundColor: "white" }}>
                        <div style={{ margin: "35px 35px 35px 35px" }}>
                            <h4>เป่ายิงฉุบ</h4>
                            <Paper square>
                                <Tabs
                                    style={{ backgroundColor: "#af0000", flexGrow: 1, color: "#fff" }}
                                    value={this.state.mainTaps}
                                    onChange={this.handleChangeMainTap}
                                    variant="fullWidth"
                                    indicatorColor="secondary"
                                    textColor="secondary"
                                    aria-label="icon label tabs example"
                                >
                                    <Tab icon={<GamepadIcon />} label="เล่นเกมส์" />
                                    <Tab icon={<PlaylistAddCheckIcon />} label="ผลแพ้ชนะ" />
                                    {/* <Tab icon={<FormatListNumberedRtlIcon />} label="อันดับ" /> */}
                                </Tabs>
                                <TabPanel value={this.state.mainTaps} index={0}>

                                    <h5>จำนวนที่เดิมพัน</h5>
                                    <div className="section over-hide z-bigger">
                                        <div className=" justify-content-center pb-6">
                                            <div className="col-xl-12 pb-6">

                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-1" value="20" />
                                                <label className="for-checkbox-budget" htmlFor="budget-1">
                                                    <span data-hover="20P">20P</span>
                                                </label>


                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-2" value="40" />
                                                <label className="for-checkbox-budget" htmlFor="budget-2">
                                                    <span data-hover="40P">40P</span>
                                                </label>


                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-3" value="60" />
                                                <label className="for-checkbox-budget" htmlFor="budget-3">
                                                    <span data-hover="60P">60P</span>
                                                </label>


                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-4" value="80" />
                                                <label className="for-checkbox-budget" htmlFor="budget-4">
                                                    <span data-hover="80P">80P</span>
                                                </label>


                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-5" value="100" />
                                                <label className="for-checkbox-budget" htmlFor="budget-5">
                                                    <span data-hover="100P">100P</span>
                                                </label>


                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-6" value="200" />
                                                <label className="for-checkbox-budget" htmlFor="budget-6">
                                                    <span data-hover="200P">200P</span>
                                                </label>

                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-7" value="400" />
                                                <label className="for-checkbox-budget" htmlFor="budget-7">
                                                    <span data-hover="400P">400P</span>
                                                </label>

                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-8" value="600" />
                                                <label className="for-checkbox-budget" htmlFor="budget-8">
                                                    <span data-hover="600P">600P</span>
                                                </label>

                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-9" value="800" />
                                                <label className="for-checkbox-budget" htmlFor="budget-9">
                                                    <span data-hover="800P">800P</span>
                                                </label>

                                                <input className="checkbox-budget" onChange={this.handlechange} type="radio" name="budget" id="budget-10" value="1000" />
                                                <label className="for-checkbox-budget" htmlFor="budget-10">
                                                    <span data-hover="1000P">1,000P</span>
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                    <h5>ตัวเลือกของท่าน</h5>
                                    <div className="section over-hide z-bigger">
                                        <div className=" justify-content-center pb-6">
                                            <div className="col-xl-12 pb-6">
                                                <input className="checkbox-tools" onChange={this.handlechange} type="radio" name="selected" id="paper" value="1" />
                                                <label className="for-checkbox-tools" htmlFor="paper">
                                                    <Image src="img/hand-paper.png" width="50px" />

                                                </label>
                                                <input className="checkbox-tools" onChange={this.handlechange} type="radio" name="selected" id="rock" value="2" />
                                                <label className="for-checkbox-tools" htmlFor="rock">
                                                    <Image src="img/hand-rock.png" width="50px" />

                                                </label>
                                                <input className="checkbox-tools" onChange={this.handlechange} type="radio" name="selected" id="scissors" value="3" />
                                                <label className="for-checkbox-tools" htmlFor="scissors">
                                                    <Image src="img/hand-scissors.png" width="50px" />

                                                </label>
                                                <input className="checkbox-tools" onChange={this.handlechange} type="radio" name="selected" id="yingchub-spin" value="4" />
                                                <label className="for-checkbox-tools" htmlFor="yingchub-spin">
                                                    <Image src="img/yingchub-spin.png" width="50px" />
                                                </label>
                                            </div>

                                        </div>
                                    </div>

                                    <Paper className="mt-25">
                                        <Tabs
                                            style={{ backgroundColor: "#007bff", flexGrow: 1, color: "#fff" }}
                                            onChange={this.handleChangeTap}
                                            value={this.state.roomTaps}
                                            variant="fullWidth"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="icon tabs example"
                                            centered
                                        >
                                            <Tab icon={<Group />} aria-label="Group" />
                                            <Tab icon={<Person />} aria-label="Person" />
                                        </Tabs>
                                        <TabPanel value={this.state.roomTaps} index={0}>
                                            <Grid container spacing={2}>
                                                <Grid direction="row"
                                                    justify="space-evenly"
                                                    alignItems="center" xs={12}>
                                                    <Grid container justify="center" >
                                                        {roomLists.map((value, index) => (
                                                            < Grid key={index} xs={12} sm={6} item >
                                                                <Paper style={{ margin: 5, padding: 10 }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item sm container>
                                                                            <Grid item xs container direction="column" spacing={2}>
                                                                                <Grid item xs>
                                                                                    <Typography gutterBottom variant="subtitle1">
                                                                                        <Chip
                                                                                            avatar={<StarBorder />}
                                                                                            label={value.orderid}
                                                                                        />
                                                                                    </Typography>
                                                                                    <Typography variant="body2" gutterBottom>
                                                                                        <div className="section over-hide z-bigger">
                                                                                            <div className="row justify-content-center ">
                                                                                                <div className="col-xl-12">
                                                                                                    <input className="checkbox-tools" type="radio" name={"room" + value.no} id={"paper" + value.no} onClick={(e) => this.handleGame(e, value)} value="1" />
                                                                                                    <label className="for-checkbox-tools" htmlFor={"paper" + value.no}>
                                                                                                        <Image src="img/hand-paper.png" width="50px" />
                                                                                                    </label>
                                                                                                    <input className="checkbox-tools" type="radio" name={"room" + value.no} id={"rock" + value.no} onClick={(e) => this.handleGame(e, value)} value="2" />
                                                                                                    <label className="for-checkbox-tools" htmlFor={"rock" + value.no}>
                                                                                                        <Image src="img/hand-rock.png" width="50px" />
                                                                                                    </label>
                                                                                                    <input className="checkbox-tools" type="radio" name={"room" + value.no} id={"scissors" + value.no} onClick={(e) => this.handleGame(e, value)} value="3" />
                                                                                                    <label className="for-checkbox-tools" htmlFor={"scissors" + value.no} >
                                                                                                        <Image src="img/hand-scissors.png" width="50px" />
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Chip
                                                                                        avatar={<AccessTime />}
                                                                                        label={moment(value.datecreate).format('YYYY-MM-DD HH:mm:ss')}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Chip
                                                                                    avatar={<AttachMoneyIcon />}
                                                                                    label={value.pointamount}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>

                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </TabPanel>
                                        <TabPanel value={this.state.roomTaps} index={1}>
                                            <Grid container spacing={2}>
                                                <Grid direction="row"
                                                    justify="space-evenly"
                                                    alignItems="center" xs={12}>
                                                    <Grid container justify="center" >
                                                        {roomplayerLists.map((value, index) => (
                                                            < Grid key={index} xs={12} sm={6} item >
                                                                <Paper style={{ margin: 5, padding: 10 }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item sm container>
                                                                            <Grid container direction="column" spacing={2}>
                                                                                <Grid item >
                                                                                    <Typography gutterBottom variant="subtitle1">
                                                                                        <Chip
                                                                                            avatar={<StarBorder />}
                                                                                            label={value.orderid}
                                                                                        />
                                                                                    </Typography>
                                                                                    <Typography variant="body2" gutterBottom>
                                                                                        <div className="section over-hide z-bigger">
                                                                                            <div className="row justify-content-center ">
                                                                                                <div className="col-xl-12 mt-25">
                                                                                                    <input className="checkbox-tools" type="radio" checked={(value.selected === 1) ? true : false} name={"room" + value.no} id={"paper" + value.no} value="1" />
                                                                                                    <label className="for-checkbox-tools" htmlFor={"paper" + value.no}>
                                                                                                        <Image src="img/hand-paper.png" width="50px" />
                                                                                                    </label>
                                                                                                    <input className="checkbox-tools" type="radio" checked={(value.selected === 2) ? true : false} name={"room" + value.no} id={"rock" + value.no} value="2" />
                                                                                                    <label className="for-checkbox-tools" htmlFor={"rock" + value.no}>
                                                                                                        <Image src="img/hand-rock.png" width="50px" />
                                                                                                    </label>
                                                                                                    <input className="checkbox-tools" type="radio" checked={(value.selected === 3) ? true : false} name={"room" + value.no} id={"scissors" + value.no} value="3" />
                                                                                                    <label className="for-checkbox-tools" htmlFor={"scissors" + value.no} >
                                                                                                        <Image src="img/hand-scissors.png" width="50px" />
                                                                                                    </label>
                                                                                                </div>
                                                                                                <Chip
                                                                                                    avatar={<AttachMoneyIcon />}
                                                                                                    label={value.pointamount}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Chip
                                                                                        avatar={<AccessTime />}
                                                                                        label={moment(value.datecreate).format('YYYY-MM-DD HH:mm:ss')}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    </Paper>
                                </TabPanel>
                                <TabPanel value={this.state.mainTaps} index={1}>
                                    <Grid container spacing={2}>
                                        <Grid direction="row"
                                            justify="space-evenly"
                                            alignItems="center" xs={12}>
                                            <Grid container justify="center" >
                                                {roomhistoryLists.map((value, index) => (
                                                    <Grid key={index} xs={12} sm={6}  >
                                                        <Paper xs style={{ margin: 5, padding: 10 }}>
                                                            <Grid container direction="column" spacing={2}>
                                                                <Grid item>
                                                                    <Typography gutterBottom variant="subtitle1">
                                                                        <Chip
                                                                            avatar={<StarBorder />}
                                                                            label={value.orderid}
                                                                        />
                                                                    </Typography>
                                                                    <Typography gutterBottom>
                                                                        <div className="section over-hide z-bigger">
                                                                            <div className=" justify-content-center pb-6">
                                                                                <div className="col-xl-12" >
                                                                                    {this.historyimage(value.selected, value.usercreate)}

                                                                                    <label className="for-checkbox-tools">
                                                                                        <Image src="img/Versus_icon.png" width="100px" className="vs-update game-image" />
                                                                                    </label>

                                                                                    {this.historyimage(value.enemyselected, value.userapporve)}
                                                                                </div>
                                                                                <div className="col-xl-12">
                                                                                    {this.checkwinlose(value.userwin, value.wintype, value.pointamount)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Chip
                                                                        avatar={<AccessTime />}
                                                                        label={moment(value.datecreate).format('YYYY-MM-DD HH:mm:ss')}
                                                                    />
                                                                </Grid>
                                                            </Grid>


                                                        </Paper>
                                                    </Grid>

                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </TabPanel>

                            </Paper>
                        </div>
                    </div >
                </div>
            </section >
        );
    }
}

export default App;
