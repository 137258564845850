import React, { Component } from "react";
import instance from "./axios-instance";
import Systems from "./SystemFunction";
class PopupManager extends Component {
    constructor(props) {
        super(props);
        const hideToday = localStorage.getItem("hidePopupToday") === "true";

        this.state = {
            currentPopupIndex: 0,
            popups: [],
            hideToday,
        };
    }
    async componentDidMount() {
        await this.getpopup();
    }
    getpopup = () => {
        instance.post("/api/v1/popuplist", {
            System: Systems,
            Cat_ID: 0,
            page: 1,
            type: 1
        }).then(async (res) => {
            console.log("PPPOPPPIPPPPUP", res.data);
            if (res.data.status === 200) {

                this.setState({
                    popups: res.data.message.tblPopupMenu1.filter(popup => popup.status === 1),
                });
            }
        }).catch(async (err) => {
        });

    }
    handleNext = () => {
        this.setState((prevState) => ({
            currentPopupIndex: Math.min(
                prevState.currentPopupIndex + 1,
                prevState.popups.length - 1
            ),
        }));
    };
    handleBack = () => {
        this.setState((prevState) => ({
            currentPopupIndex: Math.max(prevState.currentPopupIndex - 1, 0),
        }));
    };

    handleClose = () => {
        this.setState({ currentPopupIndex: null });
    };

    handleHideToday = () => {
        // Save the "Don't Show Again Today" state in localStorage
        localStorage.setItem("hidePopupToday", "true");
        this.setState({ hideToday: true, currentPopupIndex: null });
    };

    render() {
        const { currentPopupIndex, popups, hideToday } = this.state;

        if (hideToday || currentPopupIndex === null) {
            return null;
        }
        const popup = popups[currentPopupIndex];
        if (popups.length > 0) {
            return (
                <div
                    key={popup.id}
                    role="presentation"
                    style={{ display: "block" }}
                    className="MuiDialog-root MuiModal-root css-ks3red"
                >
                    <div
                        aria-hidden="true"
                        className="MuiBackdrop-root css-919eu4"
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                    />
                    <div tabIndex={0} data-testid="sentinelStart" />
                    <div
                        className="MuiDialog-container MuiDialog-scrollPaper css-ekeie0"
                        role="presentation"
                        tabIndex={-1}
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)",
                        }}
                    >
                        <div
                            className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm css-uhb5lp"
                            role="dialog"
                            aria-describedby="alert-dialog-description"
                            aria-labelledby="alert-dialog-title"
                        >
                            <h2
                                className="MuiTypography-root MuiTypography-h6 MuiDialogTitle-root css-ohyacs"
                                id="alert-dialog-title"
                            >
                                <p className="MuiTypography-root MuiTypography-body1 css-1rsvwou">
                                    ประกาศ
                                </p>
                            </h2>
                            <div className="MuiDialogContent-root css-69br6q" >

                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271000%27%20height=%271200%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="ประกาศ"
                                        src={popup.img}
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                    //   srcSet="/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbtenyu%2Fwebimages%2Fpopup%2Fbbtenyu_1728369441090.jpeg&w=1080&q=75 1x, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbtenyu%2Fwebimages%2Fpopup%2Fbbtenyu_1728369441090.jpeg&w=2048&q=75 2x"
                                    />
                                    <noscript />
                                </span>

                            </div>
                            <div className="MuiDialogActions-root MuiDialogActions-spacing css-14b29qc">
                                {currentPopupIndex > 0 && (
                                    <button
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1ujsas3"
                                        onClick={this.handleBack}
                                    >
                                        Back
                                    </button>
                                )}
                                {currentPopupIndex < popups.length - 1 ? (
                                    <button
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1ujsas3"
                                        onClick={this.handleNext}
                                    >
                                        Next
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1ujsas3"
                                            onClick={this.handleHideToday}
                                        >
                                            ไม่แสดงอีกวันนี้
                                        </button>
                                        <button
                                            className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1ujsas3"
                                            onClick={this.handleClose}
                                        >
                                            Close
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div tabIndex={0} data-testid="sentinelEnd" />
                </div>
            );
        }
    }
}

export default PopupManager;
