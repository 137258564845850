import React, { Component } from 'react'; //different
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import moment from 'moment';
import md5 from 'md5';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categorylist: [],
      rewardlist: [],
      activePage: 1,
      pagecount: 1,
      products_page_count: 1,
      isActive: false,
      activeTab: 1,
      formData: {}, // Contains login form data
      errors: {}, // Contains login field errors
      formSubmitted: false, // Indicates submit status of login form
      loading: true, // Indicates in progress state of login form
      tblprofile: [],
      userid: null,
      linkaff: "",
      point: 0,
      useradvice: [],
      balance: 0,
      loginstate: null,
      firstname: null,
      lastname: null,
      alert: null,
      modalOpen: false,
      handleClose: false,
      proderedeemlist: [],
      token: window.localStorage.getItem("token"),
      type: "",
      username: "",
      tabselect: 1,
      amount: "",
      tblwheelsetting: [],
      remark: "",
      mainacc: "",
      rankpic: "1.jpeg",
      rankname: null,
      rankob: null,
      rankpoint: 0,
      remaining_amount: 0,
      name: "",
      password: "",
      left: false,
      disable: false,
      segColors: null,
      segments: null
    }
  }
  logout = (e) => {

    // window.location.href = '/logout';

  }
  waitnotify = async () => {
    setTimeout(() => { console.log('World!') }, 5000)
  }
  async opencliaminvite(evt, cityName) {
    console.log(cityName);

    var token = localStorage.getItem('auth_token');
    if (token) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      await instance.post("/api/v1/cliaminvite", {
        userid: Username,
        System: Systems,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        if (res.data.status === 200) {
          var decoded = jwt_decode(token);
          var Username = decoded.message.playerid;
          this.rechecklogin(Username, token);
          await Swal.fire({
            title: 'Success!',
            text: 'ทำรายการรับเงินเรียบร้อย',
            icon: 'success',
            confirmButtonText: 'ตกลง'
          })

        }
        else if (res.data.status === 201) {
          await Swal.fire({
            title: 'error!',
            text: 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          })
          return false;
        }
        else if (res.data.status === 202) {
          await Swal.fire({
            title: 'error!',
            text: 'ไม่สามารถทำรายการได้',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          })
          return false;
        }

      }).catch(async (err) => {
        // console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    else {
      await Swal.fire({
        title: 'error!',
        text: 'กรุณาลีอคอิน',
        icon: 'error',
        confirmButtonText: 'ตกลง'
      })
      return false;
    }
  }
  checktab(e, index) {
    //console.log(index == 2 &&  this.state.qrcode != null);

    this.setState({
      tabselect: index,
    });


  }
  notify = async (mode, msg) => {
    console.log(mode, msg);
    if (mode == "success") {
      await toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (mode == "warning") {
      await toast.warning(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (mode == "error") {
      await toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (mode == "info") {
      await toast.info(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    await this.waitnotify();
  }
  copyCode = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.notify("warning", 'CopyCode!!!');
      })
      .catch((err) => {
        // console.log(err.message);
      });

  };
  async componentDidMount() {

    await instance.post("/api/v1/mainsetting", {
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        const datas = res.data.message;
        this.setState({
          linelink: datas[1].value,
        });
      }
      else {
        // this.CheckThisGoalError();
      }
    });

    this.getwheel();
    var userAgent = navigator.userAgent.toLowerCase()
    try {
      var token = localStorage.getItem('auth_token');
      //  //////console.log(token);
      if (token != null) {
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await this.rechecklogin(Username, token);
        this.setState({
          userid: decoded.message.playerid,
          username: decoded.message.playerid,
          firstname: decoded.message.firstname,
          lastname: decoded.message.lastname,

          loginstate: true,
        });
        this.checkuseradvice(decoded.message.playerid);
      }
      else {
        this.setState({
          loginstate: false,
        });
      }

    }
    catch (error) {
      //////console.log();
    }

  }
  openAccout(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].className += " -open";
    }
  }
  async checkuseradvice(username) {


    instance.post("/api/v1/checkuseradvice", {
      User_id: username,
      page: 1,
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.message.useradvicelist);
        this.setState({
          useradvice: res.data.message.useradvicelist
        });
      }
      else {
        // console.log(res.data);
        // this.CheckThisGoalError();
      }
    });
  }

  cancelredeempromotion = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/usercancelpromotion", {
            User_id: Username,
            System: Systems,
            no: index.no,
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              this.checkpro(null, 999);
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
            }
          });

        }
      })
    }

  }

  openAccoutcan(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].className = "x-menu-account-list-sidebar";
    }
  }

  async rechecklogin(Username, token) {

    try {
      // console.log(4);
      const search = window.location.href;
      const paramx = new URLSearchParams(search);
      // const space = search.split("/")
      const space = window.location.host
      var userhash = md5(Username);
      //  const linkaff = "https://pgline88.com/?register=" + userhash;
      const linkaff = "https://" + space + "/register?reg=" + userhash;
      instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Function: 'rechecklogin-navbar.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        console.log(5656565, res.data);
        if (res.data.status === 200) {
          localStorage.setItem('auth_token', res.data.token)
          this.setState({
            rankpic: res.data.tblrank.image,
            rankname: res.data.tblrank.name,
            linkaff: linkaff,
            rankob: res.data.tblrank,
            rankpoint: res.data.rankpoint,
            remaining_amount: res.data.message.remaining_amount,
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            tblprofile: res.data.message,
            username: res.data.message.playerid,
            fname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
        }
        else if (res.data.status === 401) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }

      }).catch(async (err) => {
        console.log(err)
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    catch (ex) {
      //////console.log(ex);
    }

  }
  checkpro = async (e, proid) => {
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    await instance.post("/api/v1/checkpromotion", {
      userid: Username,
      System: Systems,
      Proid: proid,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if (res.data.status === 200) {
        //console.log(res.data)
        // console.log(res.data.message.tblpromotionredeems)
        if (res.data.type === 0) {
          this.setState({
            prodetail: res.data.message.tblpromotions,
          });
        }
        else if (res.data.type === 1) {
          this.setState({
            proderedeemlist: res.data.message.tblpromotionredeems,
          });

        }

      }
      else if (res.data.status === 201) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }


  validateLogout = (e) => {
    localStorage.removeItem('auth_token', '')
    this.setState({
      userid: null,
      username: null,
      password: null,
      firstname: null,
      lastname: null,
      point: null,
      loginstate: false,
    });
  }
  async recheccredit(evt) {

    evt.currentTarget.className += " fa-spin";
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    //  this.rechecklogin(Username, token);
    this.setState({
      userid: decoded.message.playerid,
      username: decoded.message.playerid,
      firstname: decoded.message.firstname,
      lastname: decoded.message.lastname,
      loginstate: true,
    });
    try {
      //  console.log(5);
      await instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Function: 'recheccredit-navbar.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        //console.log(res.data);
        if (res.data.status === 200) {
          localStorage.setItem('auth_token', res.data.token)
          this.setState({
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            username: res.data.message.playerid,
            fname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
          var i, tablinks;
          tablinks = document.getElementsByClassName("-btn-balance");
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
          }
        }
        else if (res.data.status === 401) {
          localStorage.clear();
          //   console.log(res.data);
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }

      }).catch(async (err) => {
        //    console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    catch (ex) {
      //////console.log(ex);
    }
  }
  logout = (e) => {
    // e.preventDefault();
    let errors = this.validateLogout();
    if (errors === true) {
      // alert("You are successfully signed in...");
      window.location.reload()
    } else {

      this.setState({
        errors: errors,
        formSubmitted: true
      });
      window.location.reload()
    }
  }
  getwheel = async () => {
    // var segments = [{ id: 1, name: '5 บาท' }, { id: 2, name: '100 บาท' }, { id: 3, name: '300 บาท' }, { id: 4, name: '10 บาท' }, { id: 5, name: '20 บาท' }, { id: 6, name: 'คร้งหน้านะ บาท' }, { id: 7, name: 'เกือบได้แล้ว' }, { id: 8, name: 'ครั้งหน้านะ' }, { id: 9, name: '1000 บาท' }];
    try {
      await instance.post("/api/v1/getwheelspin", {
        System: Systems,
      }).then((res) => {
        console.log("getwheelspin", res.data)
        if (res.data.status === 200) {
          const datas = res.data.message;
          var segments = []
          var segColors = []
          for (var i = 0; i < datas.length; i++) {
            var id = datas[i].id
            var name = datas[i].description
            var color = datas[i].color
            var image = datas[i].image
            var setp = { id, name, image }
            segments.push(setp)
            segColors.push(color)
          }
          console.log(segColors)
          this.setState({
            tblwheelsetting: res.data.tblwheelsetting,
            segments: segments,
            segColors: segColors,
          });
          console.log(61, segments)


        }
        else {
          // this.CheckThisGoalError();
        }
      });
    }
    catch (error) {
      console.log(error)
    }



  }

  render() {


    const { loginstate, userid, username, point, firstname, lastname, balance, proderedeemlist, linkaff, tblprofile } = this.state
    // console.log(555555, this.state.segments)
    return (
      <>
        <br></br>
        <br></br>
        <br></br>
        <div className="-full-flex-wrap ">
          <div className="-full-flex-inner-bg -bg" style={{ backgroundImage: 'url(/build/web/igame-index-lobby-wm/img/index-bg.jpg?v=1)' }}>
            <div className="x-lucky-wheel-share-component  x-bg-position-center lazyloaded" data-bgset="/build/web/igame-index-lobby-wm/img/wheel-page-bg.jpg?v=1" style={{ backgroundImage: 'url("/build/web/igame-index-lobby-wm/img/wheel-page-bg.jpg?v=1")' }}>
              <div className="container position-relative">
                <div className="-btn-info-wrapper animated fadeInUp" data-animatable="fadeInUp">
                  <button className="btn -btn-info " data-ajax-modal-ondemand-user="lucky-wheel-modal-history" data-force="true" data-x-dismiss="true" data-parent-class-selector="x-lucky-wheel-history-modal -v2" data-url="/_ajax_/lucky-fortune/history?promotionId=9">
                    <i className="fas fa-history" />
                  </button>
                  <button className="btn -btn-info " data-toggle="modal" data-target="#luckyWheelConditionModal">
                    <i className="fas fa-info-circle" />
                  </button>
                </div>
              </div>
              <div className="text-center mb-4">
                <h1 className="-heading-title animated fadeInUp" data-animatable="fadeInUp">กงล้อพารวย</h1>
              </div>
              <div className="container">
                <div id="wheel" data-animatable="fadeInUp" data-delay={250} className="x-wheel-container x-bg-position-center lazyloaded animated fadeInUp" data-bgset="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/wheel-bg.png" style={{ backgroundImage: 'url("https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/wheel-bg.png")' }}>
                  <img alt="Website name Image" className="-wheel-ring" width={528} height={528} src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/wheel-ring.png" />
                  <div className="-center-pin">
                    <img alt="Website name Image" className="-wheel-pin" width={112} height={171} src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/wheel-pin.png" />
                    <img src alt="กงล้อพารวย กงล้อมหาสนุก" id="rewardImgCenter" className="-reward-img" />
                  </div>
                  {(this.state.segments === null || this.state.segments.length == 0) ? "" :
                    <div className={"x-wheel-inner-wrapper -length-" + (this.state.segments.length)} style={{ "--wheel-length": (this.state.segments.length) }} >
                      <div id="wheelSpinner" className="-spinner">
                        {this.state.segments.map((listitem, i) => (
                          <div id={"wheelRewardKey_" + listitem.id} className={"-wheel-section whell" + listitem.id}  >
                            <div className="-wheel-content-wrapper">
                              <div className="-text">{listitem.name}</div>
                              <img src={listitem.image} className="-img" alt="กงล้อพารวย กงล้อมหาสนุก" width={30} height={30} />
                            </div>
                          </div>

                        ))}
                      </div>
                    </div>
                  }

                </div >
                <button className={this.state.point > 0 ? "btn -btn-spin js-wheel-button animated fadeInUp" : "btn -btn-spin js-wheel-button disabled animated fadeInUp"} data-animatable="fadeInUp" data-delay={200} disabled={this.state.point <= 0}>
                  <span className="-text ">หมุนกงล้อ</span>
                  <img alt="Website name Image" className="img-fluid" width={30} height={30} src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/wheel-ticket.png" />
                  <span className="-text js-spin-ticket">{this.state.point}</span>
                </button>
                <audio id="sfx-wheel-spinning" src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/sfx-wheel-spinning.mp3" />
                <audio id="sfx-wheel-got-reward" src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezl/sfx-wheel-got-reward.mp3" />
              </div >
              <div className="x-wheel-progress-wrapper">
                <div className="container">
                  <div className="-inner-wrapper animated fadeInUp" data-animatable="fadeInUp">
                    <div>ฝากเงินสะสม {this.state.tblwheelsetting.length > 0 ? this.state.tblwheelsetting[0].amount : 0} บาท ได้ 1 ตั๋ว</div>
                    <div className="progress -progress mt-2">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width:
                            this.state.tblwheelsetting.length > 0 && this.state.tblwheelsetting[0].amount > 0
                              ? (this.state.remaining_amount / this.state.tblwheelsetting[0].amount) * 100 + '%'
                              : '0%', // Default to 0% if amount is not available
                        }}
                        aria-valuenow={0}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />

                      {/* <div className="progress-bar -progress-bar" role="progressbar" style={{ width: (this.state.remaining_amount-this.state.tblwheelsetting[0].amount ) +'%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} /> */}
                      <span className="-label">{this.state.remaining_amount}/{this.state.tblwheelsetting.length > 0 ? this.state.tblwheelsetting[0].amount : 0} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="x-modal modal " id="luckyWheelConditionModal" tabIndex={-1} role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true">
              <div className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-medium" role="document">
                <div className="modal-content -modal-content">
                  <button type="button" className="close f-1 " data-dismiss="modal" aria-label="Close">
                    <i className="fas fa-times" />
                  </button>
                  <div className="modal-header -modal-header">
                    <h3 className="x-title-modal d-inline-block m-auto">
                      เงื่อนไขการให้บริการ                          </h3>
                  </div>
                  <div className="modal-body -modal-body">
                    <div className="x-lucky-wheel-condition-container">
                      <p>• ฝากเงินสะสมครบ 1,000 บาท จะได้รับ 1 ตั๋ว (สูงสุด 5 ตั๋ว / วัน)<br />
                        • ยอดฝากสะสมจะ <span style={{ color: '#f39c12' }}><u><strong>รีเซ็ตเป็น 0</strong></u></span> ในเวลา 00.00 น. ของทุกวัน</p>
                      <p><span style={{ color: '#e74c3c' }}>*ตั๋วกงล้อจะถูกคำนวณและได้รับ ต่อเมื่อเข้ามาที่หน้านี้เท่านั้น หากไม่เข้าจะไม่ได้รับ</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="x-modal modal  " id="luckyWheelResultModal" tabIndex={-1} role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true">
              <div className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable " role="document">
                <div className="modal-content -modal-content">
                  <button type="button" className="close f-1 " data-dismiss="modal" aria-label="Close">
                    <i className="fas fa-times" />
                  </button>
                  <div className="modal-header -modal-header">
                    <h3 className="x-title-modal d-inline-block m-auto">
                      ขอแสดงความยินดี                          </h3>
                  </div>
                  <div className="modal-body -modal-body">
                    <div className="x-wheel-result-modal-container">
                      <p className="js-modal-wheel-result-description -description" />
                      <div className="-reward-image-wrapper">
                        <img alt="Website name Image" className="-img-bg img-fluid" src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/wheel-reward-effect-bg.png" />
                        <img src alt="กงล้อพารวย กงล้อมหาสนุก" className="js-modal-wheel-result-image -img img-fluid" />
                      </div>
                      <div className="-notice-box">
                        <strong className="-notice-box-title">รับของรางวัลได้ที่</strong>
                        <div className="-detail-wrapper">
                          <img alt="Website name Image" className="-ic-chevron img-fluid -left" src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/wheel-ic-chevron.svg" />
                          <button type="button" className="btn -btn-notify" >
                            <img alt="Website name Image" className="-ic-bell img-fluid" src="/build/web/igame-index-lobby-wm/img/menu-icon-promotion.png" />
                            <span>รับรางวัล</span>
                          </button>
                          <img alt="Website name Image" className="-ic-chevron img-fluid -right" src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/wheel-ic-chevron.svg" />
                        </div>
                      </div>
                      <button className="btn -btn-close-modal" data-dismiss="modal">
                        ปิด
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >




      </>
    );
  }
}
export default App;



