import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import md5 from 'md5';
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            checkboxcookie: false,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            settingwheel: "",
            settingcheckin: "",
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            paymentgateway: null,
            tabselect: 1,
            tabselectsec: 0,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            products_page_count: 1,
            loginstate: false,
            fullname: null,
            lname: null,
            link: "",
            alert: null,
            depositstate: 0,
            stateforget: 1,
            depositamount: 0,
            stateopterror: "",
            btnisActive: true,
            otpphone: "",
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            trueaccount: null,
            trueaccount: null,
            datas: null,
            otpphoneforget: "",
            affpercent: 0,
            counter: 300,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            cashoutlist: [],
            tblnextrank: [],
            tblrankall: [],
            maxwithdrawstatus: 0,
            tblprofile: [],
            prodetail: [],
            oldpassword: "",
            linkaff: "",
            webnamex: "",
            inviteSet: "",
            truegiftlink: "",
            proderedeem: [],
            qrcode: null,
            proderedeemlist: [],
            buttoncliam: false,
            limit: null,
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            cashoutSet: [],
            tblPopupMenu1: [],
            tblPopupMenu2: [],
            tblPopupMenu: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }


    checktab(e, index) {
        //console.log(index == 2 &&  this.state.qrcode != null);
        if (index == 2 && this.state.tabselectsec == 1) {

        }
        else {
            if (index == 2 && this.state.qrcode != null) {
                this.setState({
                    tabselect: 0,
                    tabselectsec: 1,
                });
            }
            else {
                this.setState({
                    tabselect: index,
                    tabselectsec: 0,
                });
            }
        }
    }
    onChangexdep = event => {
        ////console.log(event.target.value);
        if (event.target !== undefined) {

            this.setState({ depositamount: event.target.value });
        }
    };

    handleClickdeposit = async (e, price) => {
        this.setState({
            depositamount: price

        });
    }

    confirmdeposit = async (e, num) => {
        console.log(493, num)
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        if (token != null) {
            //   await instance.post("/api/v1/getredeempromotion", {
            //     userid: Username,
            //     System: Systems,
            //   }, {
            //     headers: { Authorization: `Bearer ${token}` }
            //   }).then(async (res) => {
            //     if (res.data.status === 200) {
            //       this.setState({
            //         proderedeem: res.data.message.tblpromotionredeems,
            //       });

            //     }
            //   }).catch(async (err) => {
            //   });
        }

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                if (num === 0) {
                    if (token != null) {
                        Username = decoded.message.playerid;
                        await    instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            console.log(16888, res.data.mainacc)
                            this.setState({
                                mainacc: res.data.mainacc,
                                paymentgateway: res.data.tblpaymentgatewayx,
                                qrcode: res.data.tblpaymentgatewayx.QRcode,
                                counter: 300,
                                tabselectsec: 1,
                                tabselect: 0
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 7) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                if (num ===7) {
                    if (token != null) {
                        Username = decoded.message.playerid;
                       await instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            console.log(16888, res.data.mainacc)
                            this.setState({
                                mainacc: res.data.mainacc,
                                paymentgateway: res.data.tblpaymentgatewayx,
                                qrcode: res.data.picture,
                                counter: 300,
                                tabselectsec: 1,
                                tabselect: 0
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else if (num === 5) {
            document.getElementById("truemonney").click()
            //deposittruemoneyModal
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    async rechecklogin(Username, token) {

        try {
            // console.log(4);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        linkaff: linkaff,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        tblprofile: res.data.message,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    async componentDidMount() {
        // this.checklogin();
        setInterval(() =>
            this.setState({
                counter: this.state.counter - 1
            })
            , 1000);
        try {
            var token = localStorage.getItem('auth_token');
            //  //////console.log(token);
            if (token != null) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                await this.rechecklogin(Username, token);
                this.setState({
                    userid: decoded.message.playerid,
                    username: decoded.message.playerid,
                    firstname: decoded.message.firstname,
                    lastname: decoded.message.lastname,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
            if (token != null) {
                var decoded = jwt_decode(token);
                let Username = decoded.message.playerid;
                instance.post("/api/v1/getqrcode", {
                    username: Username,
                    System: Systems,
                    amount: 50
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    //////console.log(res.data.picture)
                    console.log(16888, res.data.mainacc)
                    this.setState({
                        mainacc: res.data.mainacc,
                        paymentgateway: res.data.tblpaymentgatewayx,
                    });
                });
            }

            await instance.post("/api/v1/gettrue", {
                userid: Username,
                System: Systems,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                if (res.data.status === 200) {
                    console.log(res);
                    this.setState({
                        trueaccount: res.data.message.tbltruewallet[0],
                    });

                }
            }).catch(async (err) => {
            });
        }
        catch (error) {
            //////console.log();
        }
        try {

            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    // console.log( datas[7].value)
                    var min = res.data.tblcatagoryx[0].limitdeposit;
                    var max = res.data.tblcatagoryx[0].maxdeposit;
                    var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
                    var limit = { min, max }
                    this.setState({
                        maxwithdrawstatus: maxwithdrawstatus,
                        linelink: datas[1].value,
                        webnamex: datas[7].value,
                        System: res.data.tblcatagoryx[0].System,
                        cashoutSet: res.data.tblcashoutsetting[0],
                        inviteSet: res.data.tblinvitedsetting[0],
                        datas: datas,
                        limit: limit,
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        }
        catch (ex) {
            console.log(ex);
        }
    }

    render() {
        const { loginstate, userid, username, point, accnumber, paymentgateway, firstname, mainacc, lastname, balance, proderedeemlist, linkaff, trueaccount, tblprofile } = this.state
        var accnumberx;
        console.log(3399, mainacc);
        if (accnumber != null) {
            accnumberx = accnumber[0];
        }
        return (
            <>
                <div>
                    <br />
                    <br />
                    <br />
                </div>
                <section className="-contact-wrapper row m-0">
                    <div className="-games-provider-wrapper pl-0 pr-0 mx-auto -menu-index-page-bg col-12">
                        {/**/}
                    </div>
                    <div className="-games-index-page col-12">
                        <div className="mb-2 text-white-v1">
                            <div className="d-none d-lg-block">
                                <div>
                                    <br />
                                    <br />
                                    <br />
                                </div>
                                {/**/}
                                {/**/}
                                <div>{/**/}</div>
                                {/**/}
                                {/**/}
                            </div>
                            {/**/}
                            <div className="padding-router-view">
                                <div>
                                    <div className="row m-0 border-shadow-title" >
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                            <div className="text-center bg-title-web">
                                                <div>
                                                    <div className="text-title text-white-v1 text-center">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="text-white-v1-deposit">
                                                                <a role="button"
                                                                    href='/'>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={25}
                                                                        height={25}
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-left-circle text-color-back"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <div className="text-white-v1-deposit">
                                                                <h5>ฝากเงิน</h5>
                                                            </div>
                                                            <div>{/**/}</div>
                                                        </div>
                                                        <hr className="mt-0" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="x-login-index animated animatedFadeInUp fadeInUp">
                                                <div>
                                                    <div className="pt-1 pb-1">
                                                        <ul className="nav nav-tabs d-flex justify-content-center">
                                                            <li className="nav-item text-center w-50 w-margin-padding" style={{ display: mainacc == null ? "none" : "" }}>
                                                                <a
                                                                    className={this.state.tabselect == 1 ? "nav-link active" : "nav-link"}
                                                                    aria-current="page"
                                                                    href="#"
                                                                    onClick={(e) => {
                                                                        this.checktab(e, 1)
                                                                    }}
                                                                >
                                                                    <img
                                                                        className="img-bank-title"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7c0dc589-eb20-4725-98df-0531730fff00/public"
                                                                        alt=""
                                                                    />
                                                                    <span className="text-font-title-deposit">
                                                                        ธนาคาร
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            {/* <li className="nav-item text-center w-50 w-margin-padding">
                                                                <a className={this.state.tabselect == 3 ? "nav-link active" : "nav-link"}
                                                                    onClick={(e) => {
                                                                        this.checktab(e, 3)
                                                                    }}
                                                                    aria-current="page" href="#">
                                                                    <img
                                                                        className="img-bank-title"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a152f75f-0588-49aa-2836-4d9528f69000/public"
                                                                        alt=""
                                                                    />
                                                                    <span className="text-font-title-deposit">
                                                                        ทรูมันนี่
                                                                    </span>
                                                                </a>
                                                            </li> */}
                                                            <li className="nav-item text-center w-50 w-margin-padding" style={{ display: paymentgateway == null ? "none" : "" }}>
                                                                <a className={this.state.tabselect == 2 || this.state.tabselectsec == 1 ? "nav-link active" : "nav-link"}
                                                                    onClick={(e) => {
                                                                        this.checktab(e, 2)
                                                                    }}
                                                                    aria-current="page" href="#">
                                                                    <img
                                                                        className="img-bank-title"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/47a8d8e8-6424-4e84-41f7-f119c3b14e00/public"
                                                                        alt=""
                                                                    />
                                                                    <span className="text-font-title-deposit">
                                                                        สเเกน QR ฝากเงิน
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="row m-0 border-shadow-title" style={{ display: mainacc == null ? "none" : this.state.tabselect == 1 ? "" : "none" }}>
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1" style={{ display: mainacc == null ? "" : mainacc.bankid !== 6 ? "" : "none" }}>
                                            <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                <div className="mt-3">
                                                    <div className="x-login-index">
                                                        <div>
                                                            <div>
                                                                <div className="mt-3">
                                                                    <div className="text-white-v1-deposit">
                                                                        <div>
                                                                            <h6 className="mt-1 text-center text-white-v1-deposit">
                                                                                <u>บัญชีของคุณ</u>
                                                                            </h6>
                                                                            <div>
                                                                                <div>
                                                                                    <div className="box-content p-1 p-sm-1">
                                                                                        <div className="flex justify-between mx-auto">
                                                                                            <div className="sub-content w-100 p-3 text-white-v1-deposit">
                                                                                                <div className="text-center">
                                                                                                    <img
                                                                                                        className="rounded-full w-10 mb-2 flex"
                                                                                                        src={accnumber !== null ? accnumberx.img_path : ""}
                                                                                                        style={{ width: 38 }}
                                                                                                    />{" "}
                                                                                                    &nbsp; {accnumber !== null ? accnumberx.fullname_th : ""}
                                                                                                </div>
                                                                                                <div className="text-center">
                                                                                                    {firstname} &nbsp; | &nbsp;
                                                                                                    {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                                                                </div>
                                                                                                <div className="text-center pt-2">
                                                                                                    <small className="f-15 text-deposit">
                                                                                                        (
                                                                                                        กรุณาโอนเงินด้วยบัญชีที่ระบบแจ้งเท่านั้น
                                                                                                        )
                                                                                                    </small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                {mainacc !== null ?
                                                                                    <div className="text-white-v1-deposit text-center">
                                                                                        <h6 className="mb-2 mt-2 text-center">
                                                                                            <u>โอนเข้าบัญชี * ขั้นต่ำ 100 บาท</u>
                                                                                        </h6>
                                                                                        <div>
                                                                                            <div className="flex justify-between mb-1 mx-auto box-content">
                                                                                                <div className="sub-content w-100 p-3 px-lg-5">
                                                                                                    <div className="text-center">
                                                                                                        <img
                                                                                                            className="rounded-full w-10 mb-2 flex"
                                                                                                            src={mainacc.img_path}
                                                                                                            style={{ width: 38 }}
                                                                                                        />{" "}
                                                                                                        &nbsp;{mainacc.fullname_th}
                                                                                                    </div>
                                                                                                    <div className="text-center">
                                                                                                        {mainacc.first_name_en + " " + mainacc.last_name_en} :&nbsp; {
                                                                                                            mainacc.accnumber.substring(0, 3) + "-" + mainacc.accnumber.substring(3, 9) + "-" + mainacc.accnumber.substring(9, 12)
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="text-center pt-2">
                                                                                                        <a href={() => false} style={{ cursor: "pointer" }}
                                                                                                            onClick={() => navigator.clipboard.writeText(mainacc.accnumber)}>
                                                                                                            <div
                                                                                                                className="btn v-login"
                                                                                                                style={{
                                                                                                                    fontSize: 16,
                                                                                                                    cursor: "pointer"
                                                                                                                }}
                                                                                                            >
                                                                                                                เลขบัญชี :&nbsp;{" "}
                                                                                                                <span id="copy-banktransfer">
                                                                                                                    {mainacc.accnumber}
                                                                                                                </span>{" "}
                                                                                                                &nbsp;&nbsp;
                                                                                                                <span
                                                                                                                    className="badge text-bg-white p-1"
                                                                                                                    style={{
                                                                                                                        borderRadius: "33.9916px"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    &nbsp; คัดลอก &nbsp;
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-white-v1-deposit text-center">
                                                    <div className="box-content p-0 animated animatedFadeInUp fadeInUp mt-3">
                                                        <div className="sub-content p-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-white-v1-deposit pt-1">
                                                                    <img
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                        alt=""
                                                                        style={{ width: 30 }}
                                                                    />{" "}
                                                                    &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                                </div>
                                                                <div className="text-white-v1-deposit">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="flexSwitchCheckDefault"
                                                                            style={{ width: "6em", padding: 12 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/**/}
                                                {/**/}
                                            </div>
                                        </div>
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1" style={{ display: mainacc == null ? "" : mainacc.bankid === 6 ? "" : "none" }}>
                                            <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                <div className="container p-0 x-margin-top-v3">
                                                    <div className="row m-0 border-shadow-title">
                                                        <div className="col-12 p-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto my-auto -deposit">
                                                            <div className="mt-3 -deposit">
                                                                <div className="animated animatedFadeInUp fadeInUp">
                                                                    <div className="card-promptpay text-center pt-3">
                                                                        <img
                                                                            className="img-logo-promptpay"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/9c9ef88c-a34b-4aa6-1a42-54ff6cd9a800/public"
                                                                            alt=""
                                                                        />
                                                                        <span className="">QR Code ภายใน 5 นาทีเท่านั้น</span>
                                                                        <div className="pt-3 pb-2 text-left p-detail-promptpay">
                                                                            <div className="pt-2 pb-2">
                                                                                <small className="p">ระบุจำนวนเงินที่ต้องเติม</small>
                                                                            </div>
                                                                            <input
                                                                                type="text"
                                                                                id="depositamount"
                                                                                onChange={this.onChangexdep}
                                                                                value={this.state.depositamount}
                                                                                placeholder="กรอกจำนวนเงิน"
                                                                                maxLength={4}
                                                                                minLength={4}
                                                                                name="number"
                                                                                className="form-control promptpay-border-radius"
                                                                            />
                                                                            <div className="mb-1 mt-2">
                                                                                <label htmlFor="price" className="mt-1 mb-1 text-left">
                                                                                    เลือกจำนวน
                                                                                </label>
                                                                                <div className="mb-4 row m-0" style={{ color: "white" }}>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1"
                                                                                            onClick={(e) => this.handleClickdeposit(e, 100)}>
                                                                                            <div className="text-white-v1">100</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1"
                                                                                            onClick={(e) => this.handleClickdeposit(e, 300)}>
                                                                                            <div className="text-white-v1">300</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 500)}>
                                                                                            <div className="text-white-v1">500</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 1000)}>
                                                                                            <div className="text-white-v1">1,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 3000)}>
                                                                                            <div className="text-white-v1">3,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 5000)}>
                                                                                            <div className="text-white-v1">5,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 10000)}>
                                                                                            <div className="text-white-v1">10,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 30000)}>
                                                                                            <div className="text-white-v1">30,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 50000)}>
                                                                                            <div className="text-white-v1">50,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-2 pb-2">
                                                                                <button className="btn -btn-deposit w-100"
                                                                                    onClick={(e) => this.confirmdeposit(e, 7)}
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width={16}
                                                                                        height={16}
                                                                                        fill="currentColor"
                                                                                        className="bi bi-check-circle-fill"
                                                                                        viewBox="0 0 16 16"
                                                                                    >
                                                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                                    </svg>{" "}
                                                                                    สร้าง QR Code เพื่อชำระ
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-white-v1-deposit text-center">
                                                    <div className="box-content p-0 animated animatedFadeInUp fadeInUp mt-3">
                                                        <div className="sub-content p-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-white-v1-deposit pt-1">
                                                                    <img
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                        alt=""
                                                                        style={{ width: 30 }}
                                                                    />{" "}
                                                                    &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                                </div>
                                                                <div className="text-white-v1-deposit">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="flexSwitchCheckDefault"
                                                                            style={{ width: "6em", padding: 12 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/**/}
                                                {/**/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 border-shadow-title" style={{ display: this.state.tabselect == 2 ? "" : "none" }}>
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                            <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                <div className="container p-0 x-margin-top-v3">
                                                    <div className="row m-0 border-shadow-title">
                                                        <div className="col-12 p-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto my-auto -deposit">
                                                            <div className="mt-3 -deposit">
                                                                <div className="animated animatedFadeInUp fadeInUp">
                                                                    <div className="card-promptpay text-center pt-3">
                                                                        <img
                                                                            className="img-logo-promptpay"
                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/9c9ef88c-a34b-4aa6-1a42-54ff6cd9a800/public"
                                                                            alt=""
                                                                        />
                                                                        <span className="">QR Code ภายใน 5 นาทีเท่านั้น</span>
                                                                        <div className="pt-3 pb-2 text-left p-detail-promptpay">
                                                                            <div className="pt-2 pb-2">
                                                                                <small className="p">ระบุจำนวนเงินที่ต้องเติม</small>
                                                                            </div>
                                                                            <input
                                                                                type="text"
                                                                                id="depositamount"
                                                                                onChange={this.onChangexdep}
                                                                                value={this.state.depositamount}
                                                                                placeholder="กรอกจำนวนเงิน"
                                                                                maxLength={4}
                                                                                minLength={4}
                                                                                name="number"
                                                                                className="form-control promptpay-border-radius"
                                                                            />
                                                                            <div className="mb-1 mt-2">
                                                                                <label htmlFor="price" className="mt-1 mb-1 text-left">
                                                                                    เลือกจำนวน
                                                                                </label>
                                                                                <div className="mb-4 row m-0">
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1"
                                                                                            onClick={(e) => this.handleClickdeposit(e, 100)}>
                                                                                            <div className="text-white-v1">100</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1"
                                                                                            onClick={(e) => this.handleClickdeposit(e, 300)}>
                                                                                            <div className="text-white-v1">300</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 500)}>
                                                                                            <div className="text-white-v1">500</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 1000)}>
                                                                                            <div className="text-white-v1">1,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 3000)}>
                                                                                            <div className="text-white-v1">3,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 5000)}>
                                                                                            <div className="text-white-v1">5,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 10000)}>
                                                                                            <div className="text-white-v1">10,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 30000)}>
                                                                                            <div className="text-white-v1">30,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4 col-md-4 px-1">
                                                                                        <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClickdeposit(e, 50000)}>
                                                                                            <div className="text-white-v1">50,000</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-2 pb-2">
                                                                                <button className="btn -btn-deposit w-100"
                                                                                    onClick={(e) => this.confirmdeposit(e, 0)}
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width={16}
                                                                                        height={16}
                                                                                        fill="currentColor"
                                                                                        className="bi bi-check-circle-fill"
                                                                                        viewBox="0 0 16 16"
                                                                                    >
                                                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                                    </svg>{" "}
                                                                                    สร้าง QR Code เพื่อชำระ
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-white-v1-deposit text-center">
                                                    <div className="box-content p-0 animated animatedFadeInUp fadeInUp mt-3">
                                                        <div className="sub-content p-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-white-v1-deposit pt-1">
                                                                    <img
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                        alt=""
                                                                        style={{ width: 30 }}
                                                                    />{" "}
                                                                    &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                                </div>
                                                                <div className="text-white-v1-deposit">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="flexSwitchCheckDefault"
                                                                            style={{ width: "6em", padding: 12 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/**/}
                                                {/**/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 border-shadow-title" style={{ display: this.state.tabselectsec == 1 ? "" : "none" }}>
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                            <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                <div className="container p-0 x-margin-top-v3">
                                                    <div className="row m-0 border-shadow-title">
                                                        <div className="col-12 p-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto my-auto -deposit">
                                                            <div className="mt-3 -deposit">
                                                                <div className="animated animatedFadeInUp fadeInUp">
                                                                    <div className="card-promptpay text-center pt-3">
                                                                        <div className="card-body-promptpay">
                                                                            <div className="card card-2-promptpay">
                                                                                <div className="card-header card-2-promptpay-header">
                                                                                    {" "}
                                                                                    QR Code สำหรับชำระเงิน
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <div className="p-2 mb-2 bg-success-subtle">
                                                                                        <small className="text">
                                                                                            QR Code
                                                                                            สามารถสเเกนจ่ายได้เพียงครั้งเดี่ยวเท่านั้นโปรดอย่าสเเกนจ่ายซ้ำ
                                                                                        </small>
                                                                                    </div>
                                                                                    <div className="prt-2">
                                                                                        <div className="img-qr-code">
                                                                                            {this.state.qrcode !== null ?
                                                                                                <img
                                                                                                    src={this.state.qrcode}
                                                                                                    className="-img-text-promotion img-fluid ls-is-cached lazyloaded"
                                                                                                    alt=""
                                                                                                    style={{ backgroundColor: "black", height: 196, width: 196 }}
                                                                                                    height={196}
                                                                                                    width={196}
                                                                                                /> : ""
                                                                                            }
                                                                                        </div>
                                                                                        <div className="qtx-number pt-2">
                                                                                            <h5 className="font-qtx-number">
                                                                                                <b>
                                                                                                    {this.state.depositamount}{" "}
                                                                                                    <span>
                                                                                                        <img
                                                                                                            className="lang-type-bath"
                                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/d729c93e-e40d-4c6f-db8b-be26f954d200/public"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </span>
                                                                                                </b>
                                                                                            </h5>
                                                                                        </div>
                                                                                        <hr className="m-1" />
                                                                                        <div className="qtx-date-time">
                                                                                            <h5 className="font-qtx-date-time m-0">
                                                                                                <b>{this.state.counter > 0 ? this.state.counter + " วินาที" : ""} </b>
                                                                                            </h5>
                                                                                            <h5 className="text-danger remark-date-time m-0">
                                                                                                โปรดชำระเงินภายในเวลาที่กำหนด
                                                                                            </h5>
                                                                                        </div>
                                                                                        <hr className="m-1" />
                                                                                        <div className="bg-success-subtle p-2 mb-2 text-color-details">
                                                                                            <div className="font-details-qr-code">
                                                                                                {" "}
                                                                                                หากเกิดปัญหาฝากเงินหรืออื่นๆ
                                                                                                กรุณาติดต่อเจ้าหน้าที่{" "}
                                                                                                <b className="text-danger">
                                                                                                    <a role="button">
                                                                                                        Line ID : {this.state.linelink}
                                                                                                    </a>
                                                                                                </b>
                                                                                            </div>
                                                                                        </div>
                                                                                        <a role="button" className="btn btn-danger w-100">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                width={16}
                                                                                                height={16}
                                                                                                fill="currentColor"
                                                                                                className="bi bi-x-circle-fill"
                                                                                                viewBox="0 0 16 16"
                                                                                            >
                                                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                                                            </svg>{" "}
                                                                                            {this.state.counter > 0 ? "ยกเลิกรายการ" : "กรุณาทำรายการใหม่"}
                                                                                        </a>
                                                                                        {/**/}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-white-v1-deposit text-center">
                                                    <div className="box-content p-0 animated animatedFadeInUp fadeInUp mt-3">
                                                        <div className="sub-content p-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-white-v1-deposit pt-1">
                                                                    <img
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                        alt=""
                                                                        style={{ width: 30 }}
                                                                    />{" "}
                                                                    &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                                </div>
                                                                <div className="text-white-v1-deposit">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="flexSwitchCheckDefault"
                                                                            style={{ width: "6em", padding: 12 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/**/}
                                                {/**/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 border-shadow-title" style={{ display: this.state.tabselect == 3 ? "" : "none" }}>
                                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                            <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                                <div className="mt-3">
                                                    <div className="x-login-index">
                                                        <div>
                                                            <div>
                                                                <div className="mt-3">
                                                                    <div className="text-white-v1-deposit">
                                                                        <div>
                                                                            <div>
                                                                                {trueaccount !== null ?
                                                                                    <div className="text-white-v1-deposit text-center">
                                                                                        <h6 className="mb-2 mt-2 text-center">
                                                                                            <u>โอนเข้าบัญชี</u>
                                                                                        </h6>
                                                                                        <div>
                                                                                            <div className="flex justify-between mb-1 mx-auto box-content">
                                                                                                <div className="sub-content w-100 p-3 px-lg-5">
                                                                                                    <div className="text-center">
                                                                                                        <img
                                                                                                            className="rounded-full w-10 mb-2 flex"
                                                                                                            src="https://play-lh.googleusercontent.com/6I2IYbIg4rhGUgs0UxP_5q6wmJmlBjBrlQ9f0_FAN94yOzwmrtEteifCdPPd1-chY_NX"
                                                                                                            style={{ width: 38 }}
                                                                                                        />{" "}
                                                                                                        &nbsp;วอลเลท
                                                                                                    </div>
                                                                                                    <div className="text-center">
                                                                                                        {trueaccount.name} :&nbsp; {
                                                                                                            trueaccount.telnumber
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="text-center pt-2">
                                                                                                        <a href={() => false} style={{ cursor: "pointer" }}
                                                                                                            onClick={() => navigator.clipboard.writeText(trueaccount.telnumber)}>
                                                                                                            <div
                                                                                                                className="btn v-login"
                                                                                                                style={{
                                                                                                                    fontSize: 16,
                                                                                                                    cursor: "pointer"
                                                                                                                }}
                                                                                                            >
                                                                                                                วอลลเท :&nbsp;{" "}
                                                                                                                <span id="copy-banktransfer">
                                                                                                                    {trueaccount.telnumber}
                                                                                                                </span>{" "}
                                                                                                                &nbsp;&nbsp;
                                                                                                                <span
                                                                                                                    className="badge text-bg-white p-1"
                                                                                                                    style={{
                                                                                                                        borderRadius: "33.9916px"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    &nbsp; คัดลอก &nbsp;
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-white-v1-deposit text-center">
                                                    <div className="box-content p-0 animated animatedFadeInUp fadeInUp mt-3">
                                                        <div className="sub-content p-3">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text-white-v1-deposit pt-1">
                                                                    <img
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e8bbd6d-2594-4c50-3863-029c7e9a3500/public"
                                                                        alt=""
                                                                        style={{ width: 30 }}
                                                                    />{" "}
                                                                    &nbsp; รับโปรโมชั่นอัตโนมัติ
                                                                </div>
                                                                <div className="text-white-v1-deposit">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="flexSwitchCheckDefault"
                                                                            style={{ width: "6em", padding: 12 }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/**/}
                                                {/**/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

    }
}
export default App;
