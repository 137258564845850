import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import md5 from 'md5';
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            checkboxcookie: false,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            settingwheel: "",
            settingcheckin: "",
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            tabselect: 1,
            tabselectsec: 0,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            products_page_count: 1,
            loginstate: false,
            fullname: null,
            lname: null,
            link: "",
            alert: null,
            depositstate: 0,
            stateforget: 1,
            depositamount: 0,
            stateopterror: "",
            btnisActive: true,
            otpphone: "",
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            withdrawtab: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            trueaccount: null,
            datas: null,
            otpphoneforget: "",
            affpercent: 0,
            counter: 300,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            cashoutlist: [],
            tblnextrank: [],
            tblrankall: [],
            maxwithdrawstatus: 0,
            tblprofile: [],
            prodetail: [],
            oldpassword: "",
            linkaff: "",
            webnamex: "",
            inviteSet: "",
            truegiftlink: "",
            proderedeem: [],
            qrcode: null,
            proderedeemlist: [],
            buttoncliam: false,
            limit: null,
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            cashoutSet: [],
            tblPopupMenu1: [],
            tblPopupMenu2: [],
            tblPopupMenu: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }

    handleClick = async (e, price) => {
        if (price <= this.state.balance) {
            this.setState({
                withdrawamount: price
            });
        }
        else {
            await Swal.fire({
                title: 'Warning!',
                text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
                icon: 'warning',
                confirmButtonText: 'ตกลง'
            })
        }
    }
    checktab(e, index) {
        //console.log(index == 2 &&  this.state.qrcode != null);
        if (index == 2 && this.state.tabselectsec == 1) {

        }
        else {
            if (index == 2 && this.state.qrcode != null) {
                this.setState({
                    tabselect: 0,
                    tabselectsec: 1,
                });
            }
            else {
                this.setState({
                    tabselect: index,
                    tabselectsec: 0,
                });
            }
        }
    }
    onChangexdep = event => {
        ////console.log(event.target.value);
        if (event.target !== undefined) {

            this.setState({ depositamount: event.target.value });
        }
    };
    submitwithdraw = async (e) => {
        //////console.log(this.state.withdrawamount, this.state.balance);
        if (parseFloat(this.state.withdrawamount) <= parseFloat(this.state.balance)) {
            if (parseFloat(this.state.withdrawamount) >= 10) {
                var token = localStorage.getItem('auth_token');
                if (token != null) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    await instance.post("/api/v1/userwithdraw", {
                        User_id: Username,
                        System: Systems,
                        amount: this.state.withdrawamount
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            await Swal.fire({
                                title: 'Success!',
                                text: 'รายการถอนเรียบร้อย',
                                icon: 'success',
                                confirmButtonText: 'ตกลง'
                            })
                            window.location.href = '/statement';
                        }
                        else if (res.data.status === 203) {
                            await Swal.fire({
                                title: 'Error!',
                                text: 'ท่านติด Turnover อีก ' + res.data.amountturnover + " บาท",
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    });
                }
                else {
                    await Swal.fire({
                        title: 'error!',
                        text: 'รายการถอนมีปัญหา โปรดติดต่อพนักงาน',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'ขั้นต่ำในการถอน 50 บาท',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else {
            await Swal.fire({
                title: 'Warning!',
                text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
                icon: 'warning',
                confirmButtonText: 'ตกลง'
            })
        }

    }

    onChange = async (event) => {
        console.log(event);
        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            if (nam === "withdrawamount") {
                if (this.state.maxwithdrawstatus === 1) {
                    this.setState({ [nam]: this.state.balance });
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'บังคับถอนยอดทั้งหมด',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                }
                else if (Number(val) <= Number(this.state.balance)) {
                    this.setState({ [nam]: val });
                }
                else {
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                }
            } else {
                this.setState({ [nam]: val });
            }

        }
    };
    handleClickdeposit = async (e, price) => {
        this.setState({
            depositamount: price

        });
    }

    confirmdeposit = async (e, num) => {
        console.log(493, num)
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        if (token != null) {
            //   await instance.post("/api/v1/getredeempromotion", {
            //     userid: Username,
            //     System: Systems,
            //   }, {
            //     headers: { Authorization: `Bearer ${token}` }
            //   }).then(async (res) => {
            //     if (res.data.status === 200) {
            //       this.setState({
            //         proderedeem: res.data.message.tblpromotionredeems,
            //       });

            //     }
            //   }).catch(async (err) => {
            //   });
        }

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                if (num === 0) {
                    if (token != null) {
                        Username = decoded.message.playerid;
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture,
                                counter: 300,
                                tabselectsec: 1,
                                tabselect: 0
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }

        else if (num === 5) {
            document.getElementById("truemonney").click()
            //deposittruemoneyModal
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    async rechecklogin(Username, token) {

        try {
            // console.log(4);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        linkaff: linkaff,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        tblprofile: res.data.message,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    async componentDidMount() {
        // this.checklogin();
        setInterval(() =>
            this.setState({
                counter: this.state.counter - 1
            })
            , 1000);
        try {
            var token = localStorage.getItem('auth_token');
            //  //////console.log(token);
            if (token != null) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                await this.rechecklogin(Username, token);
                this.setState({
                    userid: decoded.message.playerid,
                    username: decoded.message.playerid,
                    firstname: decoded.message.firstname,
                    lastname: decoded.message.lastname,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
            if (token != null) {
                var decoded = jwt_decode(token);
                let Username = decoded.message.playerid;
                instance.post("/api/v1/getqrcode", {
                    username: Username,
                    System: Systems,
                    amount: 50
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    //////console.log(res.data.picture)
                    this.setState({
                        mainacc: res.data.mainacc,
                    });
                });
            }
        }
        catch (error) {
            //////console.log();
        }
        try {

            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {


                    const datas = res.data.message;
                    // console.log( datas[7].value)
                    var min = res.data.tblcatagoryx[0].limitdeposit;
                    var max = res.data.tblcatagoryx[0].maxdeposit;
                    var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
                    var limit = { min, max }
                    this.setState({
                        maxwithdrawstatus: maxwithdrawstatus,
                        linelink: datas[1].value,
                        webnamex: datas[7].value,
                        System: res.data.tblcatagoryx[0].System,
                        cashoutSet: res.data.tblcashoutsetting[0],
                        inviteSet: res.data.tblinvitedsetting[0],
                        datas: datas,
                        limit: limit,
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        }
        catch (ex) {
            console.log(ex);
        }
    }

    render() {
        const { loginstate, userid, username, point, accnumber, firstname, mainacc, lastname, balance, proderedeemlist, linkaff, tblprofile } = this.state
        var accnumberx;
        if (accnumber != null) {
            accnumberx = accnumber[0];
        }
        return (
            <>
                <div className="-games-index-page col-12">
                    <div className="mb-2 text-white-v1">
                        <div className="d-none d-lg-block">
                            <div>
                                <br />
                                <br />
                                <br />
                            </div>
                            {/**/}
                            {/**/}
                            <div>{/**/}</div>
                            {/**/}
                            {/**/}
                        </div>
                        {/**/}
                        <div className="padding-router-view">
                            <div>
                                <div className="row m-0 border-shadow-title" >
                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                        <div className="text-center bg-title-web">
                                            <div>
                                                <div className="text-title text-white-v1 text-center">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="text-white-v1-deposit" href='/'>
                                                            <a role="button">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={25}
                                                                    height={25}
                                                                    fill="currentColor"
                                                                    className="bi bi-arrow-left-circle text-color-back"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <div className="text-white-v1-deposit">
                                                            <h5>ถอนเงิน</h5>
                                                        </div>
                                                        <div>{/**/}</div>
                                                    </div>
                                                    <hr className="mt-0" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="x-login-index animated animatedFadeInUp fadeInUp">
                                            <div>
                                                <div className="pt-1 pb-1">
                                                    <ul className="nav nav-tabs d-flex justify-content-center">
                                                        <li className="nav-item text-center w-50">
                                                            <a
                                                                className="nav-link active"
                                                                aria-current="page"
                                                                href="#"
                                                            >
                                                                <img
                                                                    className="img-bank-title"
                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7c0dc589-eb20-4725-98df-0531730fff00/public"
                                                                    alt=""
                                                                />{" "}
                                                                ธนาคาร
                                                            </a>
                                                        </li>
                                                        {/* <li className="nav-item text-center w-50">
                      <a className="nav-link" aria-current="page" href="#">
                        <img
                          className="img-bank-title"
                          src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/a152f75f-0588-49aa-2836-4d9528f69000/public"
                          alt=""
                        />{" "}
                        ทรูมันนี่
                      </a>
                    </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row m-0 border-shadow-title" >
                                    <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                        <div className="p-1 bg-deposit-button example animated animatedFadeInUp fadeInUp">
                                            <div className="p-0 x-margin-top-v3">
                                                <div className="border-shadow-title">
                                                    <div>
                                                        <div className="mt-2 text-white-v1">
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <div className="text-white-v1">
                                                                                    <div>
                                                                                        <div className="mb-1">
                                                                                            <label
                                                                                                htmlFor="price"
                                                                                                className="mt-1 mb-1 text-left"
                                                                                            >
                                                                                                จำนวนเงิน
                                                                                            </label>
                                                                                            <div className="-x-input-icon flex-column">
                                                                                                <div className="input-group mb-1">
                                                                                                    <input
                                                                                                        id="withdraw_amount"
                                                                                                        onChange={this.onChange}
                                                                                                        name="withdrawamount"
                                                                                                        value={this.state.withdrawamount}
                                                                                                        className="form-control"
                                                                                                        placeholder={0}
                                                                                                        aria-label={0}
                                                                                                        aria-describedby="basic-addon1"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mb-1">
                                                                                            <label
                                                                                                htmlFor="price"
                                                                                                className="mt-1 mb-1 text-left"
                                                                                            >
                                                                                                เลือกจำนวน
                                                                                            </label>
                                                                                            <div className="mb-4 row m-0">
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 100)}>
                                                                                                        <div className="text-white-v1">
                                                                                                            100
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 300)}>
                                                                                                        <div className="text-white-v1">
                                                                                                            300
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 500)}>
                                                                                                        <div className="text-white-v1">
                                                                                                            500
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 1000)}>
                                                                                                        <div className="text-white-v1">
                                                                                                            1,000
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 3000)}>
                                                                                                        <div className="text-white-v1">
                                                                                                            3,000
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 5000)}>
                                                                                                        <div className="text-white-v1">
                                                                                                            5,000
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 10000)}>
                                                                                                        <div className="text-white-v1">
                                                                                                            10,000
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 30000)}>
                                                                                                        <div className="text-white-v1">
                                                                                                            30,000
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4 col-md-4 px-1">
                                                                                                    <div className="card-amount mt-2 text-white-v1" onClick={(e) => this.handleClick(e, 50000)}>
                                                                                                        <div className="text-white-v1">
                                                                                                            50,000
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/**/}
                                                                                    <div>
                                                                                        <div className="mt-1 mb-4">
                                                                                            <div>
                                                                                                <button
                                                                                                    onClick={(e) => this.submitwithdraw(e)}
                                                                                                    className="btn -submit js-submit-accept-term v-login btn-success mt-lg-1 mt-0 w-100 p-2">
                                                                                                    ถัดไป
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/**/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/**/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/**/}
                                        </div>
                                    </div>
                                </div>
                                {/**/}
                            </div>
                        </div>

                    </div>
                </div>

            </>

        );

    }
}
export default App;
